import { setConfig, setConfigLoading } from "./orderFormSlice";
import type { AppGetState, LocalDispatch } from "../types/orderForm";

export const setupOrderFormConfig =
  (baseUrl: string, orderFormUrl: string) => async (dispatch: LocalDispatch, getState: AppGetState) => {
    const { orderForm } = getState();

    if (orderForm.config || orderForm.isConfigLoading) return;

    dispatch(setConfigLoading(true));

    try {
      const rawResponse = await fetch(`${orderFormUrl}/orderform`, {
        headers: {
          "Content-Type": "application/json",
          "x-host": baseUrl || location.hostname,
        },
      });

      if (rawResponse.status > 299) {
        throw new Error();
      }

      const response = await rawResponse.json();
      dispatch(setConfig(response));
    } catch (err) {
      // retry
      setTimeout(() => {
        dispatch(setupOrderFormConfig(baseUrl, orderFormUrl));
      }, 5000);
    }
    dispatch(setConfigLoading(false));
  };
