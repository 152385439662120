import { useCallback, useEffect, useRef, useState, KeyboardEvent, ChangeEvent } from "react";
import cn from "classnames";
import s from "./InputNumber.module.scss";

export type InputNumberProps = {
  min: number;
  max: number;
  step?: number;
  placeholder?: string;
  defaultValue?: number | null;
  currentValue?: number | null;
  onValueChange: (value?: number) => void;
  className?: string;
  name?: string;
};

export const InputNumber = ({
  min,
  max,
  step = 1,
  placeholder,
  defaultValue,
  currentValue,
  onValueChange,
  className,
  name,
}: InputNumberProps) => {
  const [numberValue, setNumberValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [minDisabled, setMinDisabled] = useState(!(Number(defaultValue) > min));
  const [plusDisabled, setPlusDisabled] = useState(!(Number(defaultValue) < max));
  const callBackRef = useRef<(numberValue: number, inputValue: string) => void>();

  callBackRef.current = onValueChange;

  const updateValues = (newNumberValue: number, newInputValue: string | number) => {
    setNumberValue(newNumberValue);
    setInputValue(newInputValue);
  };

  const changeValue = useCallback(
    (newNumberValue: number, newInputValue: string | number) => {
      if (newNumberValue <= min) {
        updateValues(min, newInputValue);
        setMinDisabled(true);
        setPlusDisabled(false);
      } else if (newNumberValue >= max) {
        updateValues(max, newInputValue);
        setMinDisabled(false);
        setPlusDisabled(true);
      } else {
        updateValues(newNumberValue, newInputValue);
        setMinDisabled(false);
        setPlusDisabled(false);
      }
    },
    [max, min]
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") {
      changeValue(min, "");
    } else {
      changeValue(+value, value);
    }
  };

  const onInputBlur = () => {
    const value = Number(currentValue);
    // fix step
    const div = value % step;
    const diff = step - div;
    const fixedValue = value < min ? min : value > max ? max : div ? value + diff : value;

    console.log("fixed value", fixedValue);

    changeValue(fixedValue, fixedValue);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const forbiddenKeys = ["+", "-", "e", "."];

    if (forbiddenKeys.includes(event.key)) event.preventDefault();
  };

  useEffect(() => {
    if (currentValue === null) {
      changeValue(min, "");
    } else if (Number(currentValue) < min) {
      changeValue(min, currentValue || "");
    } else if (Number(currentValue) > max) {
      changeValue(max, currentValue || "");
    } else {
      changeValue(currentValue || 0, currentValue || "");
    }
  }, [currentValue, min, max, changeValue]);

  useEffect(() => {
    if (!callBackRef.current) return;
    callBackRef.current(Number(numberValue), String(inputValue));
  }, [numberValue, inputValue]);

  return (
    <div className={cn(s.wrapper, className)}>
      <label className="core-input-number">
        <button
          type="button"
          aria-label="minus"
          className={cn("core-input-number__btn", "core-input-number__btn--minus")}
          disabled={minDisabled}
          onClick={() => {
            const newValue = numberValue ? numberValue - step : max;
            changeValue(newValue, String(newValue));
          }}
        />
        <input
          type="number"
          placeholder={placeholder}
          min={min}
          max={max}
          name={name}
          className="core-calculator-input"
          value={inputValue}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onBlur={onInputBlur}
        />
        <button
          type="button"
          aria-label="plus"
          className={cn("core-input-number__btn", "core-input-number__btn--plus")}
          disabled={plusDisabled}
          onClick={() => {
            const newValue = numberValue ? numberValue + step : min;
            changeValue(newValue, String(newValue));
          }}
        />
      </label>
    </div>
  );
};

export default InputNumber;
