export const debounceEvent = (callback: any, time: number) => {
  let interval: any;
  return (...args: any) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;

      callback(...args);
    }, time);
  };
};
