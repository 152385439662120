import { useState, useEffect, useRef } from "react";
import { Dropdown } from "./Dropdown";
import cn from "classnames";
import { DropdownRangeToggleButton } from "./DropdownRangeToggleButton";
import { DropdownItem } from "./DropdownItem";

type DropdownRangeProps = {
  error?: boolean;
  selectedValue?: number;
  onValueChange: (value?: number) => void;
  items: {
    id: number;
    value: string | number;
  }[];
};

export const DropdownRange = ({
  items,
  selectedValue = items && items[0].id,
  error,
  onValueChange,
}: DropdownRangeProps) => {
  const [value, setValue] = useState(selectedValue);
  const [minDisabled, setMinDisabled] = useState(false);
  const [plusDisabled, setPlusDisabled] = useState(false);
  const onChangeRef = useRef<(value?: number) => void>();
  onChangeRef.current = onValueChange;
  const selectedValueRef = useRef<number | undefined>();
  selectedValueRef.current = selectedValue;

  useEffect(() => {
    const targetIndex = items.findIndex((el) => el.id === value);

    if (targetIndex === 0) {
      setMinDisabled(true);
      setPlusDisabled(false);
    } else if (targetIndex === items.length - 1) {
      setMinDisabled(false);
      setPlusDisabled(true);
    } else {
      setMinDisabled(false);
      setPlusDisabled(false);
    }
  }, [value, items]);

  useEffect(() => {
    if (!onChangeRef.current) return;
    onChangeRef.current(value);
  }, [value]);

  useEffect(() => {
    setValue(selectedValue);
    if (onChangeRef.current) onChangeRef.current(selectedValue);
  }, [selectedValue]);

  return (
    <Dropdown
      className={cn("core-dropdown--range", {
        "core-dropdown--error": error,
      })}
      type="range"
      items={items}
      ButtonComponent={DropdownRangeToggleButton}
      ItemComponent={DropdownItem}
      buttonProps={{
        error,
        value,
        setValue,
        minDisabled,
        plusDisabled,
      }}
      itemProps={{
        value,
        setValue,
      }}
    />
  );
};
