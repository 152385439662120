import { useState, useRef, useEffect } from "react";
import { Dropdown } from "./Dropdown";
import { DropdownSelectToggleButton } from "./DropdownSelectToggleButton";
import { DropdownItem } from "./DropdownItem";

type DropdownSelectProps = {
  error?: boolean;
  items: {
    id: number;
    value: string | number;
  }[];
  onValueChange: (value?: number) => void;
  placeholder?: string;
  selectedValue?: number;
};

export const DropdownSelect = ({
  items,
  placeholder,
  selectedValue = items?.length && items[0].id,
  error,
  onValueChange,
}: DropdownSelectProps) => {
  const [value, setValue] = useState(selectedValue);
  const onChangeRef = useRef<(value?: number) => void>();
  onChangeRef.current = onValueChange;
  const selectedValueRef = useRef<number | undefined>();
  selectedValueRef.current = selectedValue;

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (value !== selectedValueRef.current) {
      onChangeRef.current?.(value);
    }
  }, [value]);

  return (
    <Dropdown
      type="select"
      items={items}
      ButtonComponent={DropdownSelectToggleButton}
      ItemComponent={DropdownItem}
      buttonProps={{ error, value, placeholder }}
      itemProps={{ value, setValue }}
    />
  );
};
