import { FormField } from "./FormField";
import { DropdownSelect } from "./DropdownSelect";
import { CalculatorFormState, FormPaperUnit, IAcademicLevel, IWorkType } from "../types/orderForm";
import { FieldTabs } from "./FieldTabs";
import { InputNumber } from "./InputNumber";
import { DropdownRange } from "./DropdownRange";
import { getDateRange } from "../helpers/getDateRange";

type CalculatorFormProps = {
  workTypes: IWorkType[];
  academicLevels: IAcademicLevel[];
  formValues: CalculatorFormState;
  updateValues: (values: CalculatorFormState) => void;
  wordChunkSize: number;
  shortestDeadline: number;
  handlePaperVolumeSwitch: (value: FormPaperUnit) => void;
  getUpdatedDeadline: (id: number) => number | undefined;
};

const MIN_UNITS = 1;
const MIN_WORDS = 100;

export const CalculatorForm = ({
  workTypes,
  formValues,
  updateValues,
  academicLevels,
  wordChunkSize,
  shortestDeadline,
  handlePaperVolumeSwitch,
  getUpdatedDeadline,
}: CalculatorFormProps) => (
  <div className="core-calculator-form">
    <FormField label="Type of work">
      <DropdownSelect
        items={workTypes.map(({ id, customerName }) => ({ id, value: customerName }))}
        selectedValue={formValues.idWorkType}
        onValueChange={(value) => {
          if (!value) return;
          updateValues({ idWorkType: value });
        }}
      />
    </FormField>
    <FormField label="Academic level">
      <DropdownSelect
        items={academicLevels.map(({ id, customerName }) => ({ id, value: customerName }))}
        selectedValue={formValues.idAcademicLevel}
        onValueChange={(value) => {
          const newShortestDeadline = academicLevels.find(({ id }) => id === value)?.shortestDeadline;
          if (!value || !newShortestDeadline) return;

          updateValues({
            idAcademicLevel: value,
            deadline: getUpdatedDeadline(Number(value)),
          });
        }}
      />
    </FormField>

    <FieldTabs
      onTabChange={(key: FormPaperUnit) => {
        handlePaperVolumeSwitch(key);
      }}
      items={[
        {
          label: "Pages",
          key: "units",
          tabPane: (
            <FormField>
              <InputNumber
                min={MIN_UNITS}
                max={100}
                defaultValue={MIN_UNITS}
                currentValue={formValues.units || null}
                onValueChange={(value) => {
                  if (!value) return;
                  updateValues({ words: undefined, units: value });
                }}
              />
            </FormField>
          ),
        },
        {
          label: "Words",
          key: "words",
          tabPane: (
            <FormField>
              <InputNumber
                min={MIN_WORDS}
                max={60000}
                step={wordChunkSize}
                defaultValue={null}
                currentValue={formValues.words || null}
                onValueChange={(value) => {
                  if (!value) return;
                  updateValues({ units: undefined, words: value });
                }}
              />
            </FormField>
          ),
        },
      ]}
    />

    <FormField label="Urgency">
      <DropdownRange
        items={getDateRange(shortestDeadline)}
        selectedValue={formValues.deadline}
        onValueChange={(value) => {
          if (!value) return;
          updateValues({ deadline: value });
        }}
      />
    </FormField>
  </div>
);
