import { PropsWithChildren } from "react";

type FormFieldProps = {
  label?: string;
};

export const FormField = ({ label, children }: PropsWithChildren<FormFieldProps>) => {
  return (
    <div className="core-calculator-form-field">
      {label && <div className="core-calculator-form-field__label">{label}</div>}
      <div className="core-calculator-form-field__control">{children}</div>
    </div>
  );
};
