import type { ExtraFeatureAnySetting, IPriceQuoteRequest } from "../types/orderForm";
import { getExtraFeatureName } from "../store/extraFeatures";
import { ReactNode } from "react";
import { ExtraFeatureTypes } from "../types/orderForm";

type ExtraFeaturesParams = {
  items: ExtraFeatureAnySetting[];
  selectedIds: number[];
  updateValues: (
    values: Partial<IPriceQuoteRequest> & {
      selectedFeatures?: number[];
    }
  ) => void;
  render: (
    idExtraFeatureType: ExtraFeatureTypes,
    isActive: boolean,
    onClick: () => void,
    text: string
  ) => ReactNode;
};

export const renderExtraFeatures = ({ updateValues, items, selectedIds, render }: ExtraFeaturesParams) =>
  items.map(({ idExtraFeatureType }) =>
    render(
      idExtraFeatureType,
      selectedIds.includes(idExtraFeatureType),
      () => {
        updateValues({
          selectedFeatures: selectedIds.includes(idExtraFeatureType)
            ? selectedIds.filter((value) => value !== idExtraFeatureType)
            : [...selectedIds, idExtraFeatureType],
        });
      },
      getExtraFeatureName(idExtraFeatureType)
    )
  );
