import { useState, useRef, useEffect, useCallback, ComponentType } from "react";
import cn from "classnames";
import { debounceEvent } from "../helpers/debounceEvent";
import { DropdownItemProps } from "./DropdownItem";
import { DropdownRangeToggleButtonProps } from "./DropdownRangeToggleButton";
import { DropdownSelectToggleButtonProps } from "./DropdownSelectToggleButton";
import s from "./Dropdown.module.scss";

export type DropdownProps = {
  className?: string;
  ItemComponent: ComponentType<DropdownItemProps>;
  itemProps: Omit<DropdownItemProps, "item" | "onClick">;
  items: {
    id: number;
    value: string | number;
  }[];
} & (
  | {
      type: "select";
      ButtonComponent: ComponentType<DropdownSelectToggleButtonProps>;
      buttonProps: Omit<DropdownSelectToggleButtonProps, "onClick" | "items">;
    }
  | {
      type: "range";
      ButtonComponent: ComponentType<DropdownRangeToggleButtonProps>;
      buttonProps: Omit<DropdownRangeToggleButtonProps, "onClick" | "items">;
    }
);

export const Dropdown = ({
  ItemComponent,
  ButtonComponent,
  buttonProps,
  itemProps,
  items,
  type,
  className,
}: DropdownProps) => {
  const [dropdownItems, setDropdownItems] = useState<
    {
      id: number;
      value: string | number;
    }[]
  >(items);
  const [open, setOpen] = useState(false);
  const [below, setBelow] = useState(true);
  const dropdownBody = useRef<HTMLDivElement>(null);
  const dropdownBtn = useRef<HTMLDivElement>(null);

  const closeDropdown = () => {
    setOpen(false);
    setBelow(true);
    dropdownBody.current?.scrollTo({ top: 0 });
  };

  const openDropdown = () => {
    if (!dropdownBody.current) return;
    setOpen(true);
    const selectedElem = dropdownBody.current.querySelector(".core-dropdown__link--selected");
    if (!selectedElem) return;
    setTimeout(() => {
      if (!dropdownBody.current) return;
      const diff =
        dropdownBody.current.getBoundingClientRect().top -
        selectedElem.getBoundingClientRect().top -
        dropdownBody.current.scrollTop +
        5;
      dropdownBody.current.scrollTop = -1 * diff;
    }, 0);
  };

  const onClick = () => {
    open ? closeDropdown() : openDropdown();
  };

  const checkDropdownPosition = () => {
    if (!dropdownBtn.current || !dropdownBody.current) return;
    const winTop = window.scrollY;
    const elHeight = dropdownBtn.current.clientHeight + dropdownBody.current.clientHeight;
    const elTop = winTop + dropdownBtn.current.getBoundingClientRect().top;

    const result =
      elTop + elHeight <= winTop + window.innerHeight && elTop + elHeight < document.body.clientHeight;

    setBelow(result);
  };

  const debouncedCheckDropdownPosition = debounceEvent(checkDropdownPosition, 100);

  const onDocClick = useCallback((event: MouseEvent) => {
    if (!dropdownBtn.current || !event.target) return;
    if (dropdownBtn.current === event.target || dropdownBtn.current?.contains(event.target as Node)) return;
    if (!dropdownBody.current?.contains(event.target as Node)) closeDropdown();
  }, []);

  useEffect(checkDropdownPosition, [open, dropdownBody]);

  useEffect(() => {
    document.addEventListener("click", onDocClick);
    window.addEventListener("scroll", debouncedCheckDropdownPosition);
    window.addEventListener("resize", debouncedCheckDropdownPosition);

    return () => {
      document.removeEventListener("click", onDocClick);
      window.removeEventListener("scroll", debouncedCheckDropdownPosition);
      window.removeEventListener("resize", debouncedCheckDropdownPosition);
    };
  }, [debouncedCheckDropdownPosition, onDocClick]);

  useEffect(() => {
    setDropdownItems(items);
  }, [items]);

  return (
    <div className={s.dropdown}>
      <div
        className={cn("core-dropdown", className, {
          "core-dropdown--open": open,
          "core-dropdown--open-below": open && below,
          "core-dropdown--open-above": open && !below,
        })}
      >
        <div ref={dropdownBtn}>
          <ButtonComponent onClick={onClick} items={dropdownItems} {...buttonProps} />
        </div>
        <div ref={dropdownBody} className="core-dropdown__items-wrap">
          <ul className="core-dropdown__items-list">
            {dropdownItems.map((item, i) => (
              <li key={i} className="core-dropdown__item">
                <ItemComponent item={item} onClick={closeDropdown} {...itemProps} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
