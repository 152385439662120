import {
  ExtraFeatureAnySetting,
  ExtraFeatureTypes,
  IOrderFormConfig,
  IPriceQuoteRequest,
} from "../types/orderForm";

export const getExtraFeatureName = (idType: ExtraFeatureTypes) =>
  ({
    [ExtraFeatureTypes.PremiumWriter]: "Premium Writer",
    [ExtraFeatureTypes.PreferredWriter]: "Preferred Writer",
    [ExtraFeatureTypes.ExtraQualityCheck]: "Extra Quality Check",
    [ExtraFeatureTypes.OriginalityReport]: "Originality Report",
    [ExtraFeatureTypes.AIReport]: "AI Report",
    [ExtraFeatureTypes.DraftFeature]: "Draft Feature",
  }[idType]);

export const sanitizeExtraFeaturesRequest = (
  values: Partial<IPriceQuoteRequest> & {
    selectedFeatures?: number[];
  }
) => {
  if (!values) return values;
  const updatedValues = { ...values };

  if ("selectedFeatures" in updatedValues) {
    updatedValues.premiumWriter =
      updatedValues.selectedFeatures?.includes(ExtraFeatureTypes.PremiumWriter) || undefined;
    updatedValues.extraQualityCheck =
      updatedValues.selectedFeatures?.includes(ExtraFeatureTypes.ExtraQualityCheck) || undefined;
    updatedValues.originalityReport =
      updatedValues.selectedFeatures?.includes(ExtraFeatureTypes.OriginalityReport) || undefined;
    updatedValues.aiReport =
      updatedValues.selectedFeatures?.includes(ExtraFeatureTypes.AIReport) || undefined;
    updatedValues.draftFeature =
      updatedValues.selectedFeatures?.includes(ExtraFeatureTypes.DraftFeature) || undefined;
    delete updatedValues.selectedFeatures;
  } else {
    updatedValues.premiumWriter = undefined;
    updatedValues.extraQualityCheck = undefined;
    updatedValues.originalityReport = undefined;
    updatedValues.aiReport = undefined;
    updatedValues.draftFeature = undefined;
  }

  return updatedValues;
};

export const getExtraFeaturesFromEntity = (entity?: IOrderFormConfig): ExtraFeatureAnySetting[] => {
  if (!entity) return [];

  const result: ExtraFeatureAnySetting[] = [];

  if (entity.premiumWriterSetting) {
    result.push({ ...entity.premiumWriterSetting, idExtraFeatureType: ExtraFeatureTypes.PremiumWriter });
  }

  if (entity.originalityReportSetting) {
    result.push({
      ...entity.originalityReportSetting,
      idExtraFeatureType: ExtraFeatureTypes.OriginalityReport,
    });
  }

  if (entity.extraQualityCheckSetting) {
    result.push({
      ...entity.extraQualityCheckSetting,
      idExtraFeatureType: ExtraFeatureTypes.ExtraQualityCheck,
    });
  }

  if (entity.AIReportSetting) {
    result.push({
      ...entity.AIReportSetting,
      idExtraFeatureType: ExtraFeatureTypes.AIReport,
    });
  }

  if (entity.draftFeatureSetting) {
    result.push({
      ...entity.draftFeatureSetting,
      idExtraFeatureType: ExtraFeatureTypes.DraftFeature,
    });
  }

  return result;
};
