import { useOrderFormInitialSetup } from "@/lib/priceCalculator/helpers/useOrderFormInitialSetup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useOrderForm } from "@/lib/priceCalculator/helpers/useOrderForm";
import { CalculatorFormState, FormPaperUnit } from "@/lib/priceCalculator/types/orderForm";
import { getPriceQuote } from "@/lib/priceCalculator/store/getPriceQuote";

const MAX_WORDS = 60000;
const MIN_WORDS = 100;
const MAX_UNITS = 100;
const MIN_UNITS = 1;

export const useCalculatorForm = (baseUrl: string, orderFormUrl: string) => {
  const { config, formValues, setFormValues, defaults, dispatch } = useOrderFormInitialSetup(
    baseUrl,
    orderFormUrl
  );
  const [price, setPrice] = useState(0);

  const { shortestDeadline, validatePayload, wordChunkSize, submit, paperUnit, minWordCount } = useOrderForm(
    config,
    formValues,
    defaults,
    baseUrl
  );

  // save previous given 'words' value (preserve value when switching between words and pages and pages count not changed)
  const [prevWords, setPrevWords] = useState<number | undefined>(formValues.words);

  const { units, words } = formValues;

  const workTypes = config?.workTypes || [];
  const academicLevels = useMemo(() => config?.academicLevels || [], [config]);

  const updateValues = useCallback(
    (values: CalculatorFormState) => {
      setFormValues({ ...formValues, ...values });
    },
    [formValues, setFormValues]
  );

  const handlePaperVolumeSwitch = useCallback(
    (type: FormPaperUnit) => {
      // save previous given 'words' value
      const unitKey = units ? "units" : words ? "words" : "";

      if (words) {
        setPrevWords(words);
      }
      const wordCount = Number(paperUnit?.wordCount);
      const wordCountMultiplier = 1;

      const wordsCalcValues = [Number(units) - 1, Number(units), Number(units) + 1].map((value) =>
        Math.ceil(value * wordCount * wordCountMultiplier)
      );
      const unitsCalcValue = Math.ceil(Number(words) / wordCount / wordCountMultiplier);

      let wordsCalcValue = wordsCalcValues[1];

      if (
        unitKey === "units" &&
        !!prevWords &&
        type === "words" &&
        prevWords > wordsCalcValues[0] &&
        prevWords < wordsCalcValues[1]
      ) {
        wordsCalcValue = prevWords;
      }

      const minWords = Number(minWordCount) || MIN_WORDS;

      let wordsValue = words || wordsCalcValue;
      if (wordsValue < minWords) wordsValue = minWords;
      if (wordsValue > MAX_WORDS) wordsValue = MAX_WORDS;

      let unitsValue = units || unitsCalcValue;

      if (unitsValue < MIN_UNITS) unitsValue = MIN_UNITS;
      if (unitsValue > MAX_UNITS) unitsValue = MAX_UNITS;

      updateValues({ [type]: type === "words" ? wordsValue : unitsValue });
    },
    [prevWords, paperUnit?.wordCount, units, words, updateValues, minWordCount]
  );

  // setting up default values
  useEffect(() => {
    if (!config || !validatePayload(formValues)) return;

    dispatch(getPriceQuote(formValues, baseUrl, orderFormUrl)).then((response) => {
      if (response) {
        setPrice(response.dynamicTotalPrice);
      }
    });
  }, [validatePayload, formValues, dispatch, config, baseUrl, orderFormUrl]);

  const getUpdatedDeadline = useCallback(
    (idLevel: number) => {
      const selectedLevel = academicLevels.find(({ id }) => id === idLevel);
      if (!selectedLevel) return formValues.deadline;
      if (selectedLevel.shortestDeadline > (formValues.deadline || 0)) {
        return selectedLevel.shortestDeadline;
      }

      return formValues.deadline;
    },
    [formValues, academicLevels]
  );

  return {
    workTypes,
    academicLevels,
    formValues,
    updateValues,
    wordChunkSize,
    shortestDeadline,
    submit,
    price,
    handlePaperVolumeSwitch,
    getUpdatedDeadline,
    config,
  };
};
