import cn from "classnames";
import { MouseEvent } from "react";

export type DropdownItemProps = {
  onClick?: (event: MouseEvent) => void;
  item: {
    id: number;
    value: string | number;
  };

  value?: number;
  setValue: (value: number) => void;
};

export const DropdownItem = ({ item, onClick, value, setValue }: DropdownItemProps) => {
  return (
    <button
      type="button"
      className={cn("core-dropdown__link", {
        "core-dropdown__link--selected": value === item.id,
      })}
      dangerouslySetInnerHTML={{ __html: "" + item.value }}
      onClick={(event) => {
        onClick?.(event);
        setValue(item.id);
      }}
    />
  );
};
