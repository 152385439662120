import { useAppDispatch, useAppSelector } from "../types/orderForm";
import { orderFormConfigSelector } from "../store/orderFormSlice";
import { getOrderFormInitialParams } from "./getOrderFormInitialParams";
import { useEffect, useState } from "react";
import type { IPriceQuoteRequest } from "../types/orderForm";
import { useInteraction } from "./useInteraction";
import { setupOrderFormConfig } from "../store/setupOrderFormConfig";

export const useOrderFormInitialSetup = (baseUrl: string, orderFormUrl: string) => {
  const config = useAppSelector(orderFormConfigSelector);
  const defaults = getOrderFormInitialParams(config);

  const [formValues, setFormValues] = useState<Partial<IPriceQuoteRequest> & { selectedFeatures?: number[] }>(
    defaults
  );

  const dispatch = useAppDispatch();
  const { isInteracted } = useInteraction();

  useEffect(() => {
    if (!isInteracted) return;
    dispatch(setupOrderFormConfig(baseUrl, orderFormUrl));
  }, [baseUrl, dispatch, isInteracted, orderFormUrl]);

  useEffect(() => {
    if (config) {
      setFormValues(getOrderFormInitialParams(config));
    }
  }, [config]);

  return {
    config,
    formValues,
    defaults,
    setFormValues,
    dispatch,
  };
};
