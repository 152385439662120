import { ReactNode, useState } from "react";
import cn from "classnames";
import { FormPaperUnit } from "@/lib/priceCalculator/types/orderForm";

type FieldTabItem = {
  label: string;
  key: FormPaperUnit;
  tabPane: ReactNode;
};

type FieldTabsProps = {
  items: FieldTabItem[];
  onTabChange: (key: FormPaperUnit) => void;
};

export const FieldTabs = ({ items, onTabChange }: FieldTabsProps) => {
  const [activeTab, setActiveTab] = useState(items[0].key);
  return (
    <div className="core-calculator-field-tabs">
      <div className="core-calculator-field-tabs__links">
        {items.map(({ label, key }) => (
          <button
            type="button"
            key={key}
            className={cn("core-calculator-field-tabs__link", {
              "core-calculator-field-tabs__link--active": activeTab === key,
            })}
            onClick={() => {
              if (key === activeTab) return;
              setActiveTab(key);
              onTabChange(key);
            }}
          >
            {label}
          </button>
        ))}
      </div>
      <div className="core-calculator-field-tabs__content">
        {items.find(({ key }) => key === activeTab)?.tabPane}
      </div>
    </div>
  );
};
