import { useState, useEffect, useCallback } from "react";

type AnimatedNumberProps = {
  number: number;
  showZeros?: boolean;
};
export const AnimatedNumber = ({ number, showZeros }: AnimatedNumberProps) => {
  const [initialNumber, setInitialNumber] = useState(0);
  const [currentNumber, setCurrentNumber] = useState(number);
  const [animatedNumber, setAnimatedNumber] = useState("");

  const animateNumber = useCallback(
    (start = 0, end: number, duration = 500) => {
      let startTimestamp = 0;
      const step = (timestamp: number) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const animatedValue = Math.floor(progress * (end - start) + start);

        const animatedStringValue =
          showZeros && animatedValue < 10 ? "0" + animatedValue : String(animatedValue);

        setAnimatedNumber(animatedStringValue);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    [showZeros]
  );

  useEffect(() => {
    setCurrentNumber(number);
    if (currentNumber === number) return;
    setInitialNumber(currentNumber);
  }, [number, currentNumber]);

  useEffect(() => {
    animateNumber(initialNumber, currentNumber);
  }, [animateNumber, currentNumber, initialNumber]);

  return <>{animatedNumber}</>;
};

export default AnimatedNumber;
