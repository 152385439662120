import type { IOrderFormConfig, IPriceQuoteRequest } from "../types/orderForm";

const getFirstId = (arr: { id: number }[] | undefined): number => {
  if (!(Array.isArray(arr) && arr.length)) return 0;
  return arr[0]?.id || 0;
};

export const getOrderFormInitialParams = (config?: IOrderFormConfig | null): Partial<IPriceQuoteRequest> =>
  config
    ? {
        idAcademicDiscipline: getFirstId(config.academicDisciplines),
        idAcademicLevel: getFirstId(config.academicLevels),
        idLineSpacing: getFirstId(config.paperTypes[0].unit.lineSpacings),
        idWorkType: getFirstId(config.workTypes),
        deadline: 336, // 17 days (minimum price)
        discountCode: null,
        idExtraFeatures: [],
        idPaperType: getFirstId(config.paperTypes),
        units: 1,
        premiumWriter: false,
        preferredWriter: false,
        extraQualityCheck: false,
        originalityReport: false,
      }
    : {};
