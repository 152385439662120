import cn from "classnames";
import { MouseEvent } from "react";

export type DropdownSelectToggleButtonProps = {
  onClick?: (event: MouseEvent) => void;
  error?: boolean;
  value?: number;
  items: {
    id: number;
    value: number | string;
  }[];

  placeholder?: string;
};

export const DropdownSelectToggleButton = ({
  onClick,
  error,
  items,
  value,
  placeholder = "",
}: DropdownSelectToggleButtonProps) => {
  const hasSelectedValue = typeof value !== undefined;
  return (
    <button
      type="button"
      className={cn("core-calculator-input", "core-dropdown__toggle-btn")}
      onClick={onClick}
    >
      {hasSelectedValue ? items.find((item) => item.id === value)?.value : placeholder}
      <span className="core-dropdown__toggle-btn-icon" />
    </button>
  );
};
