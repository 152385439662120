import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export type DefaultCatalog = {
  id: number;
  customerName: string;
  customerDescription?: string;
};

export enum AcademicDegrees {
  HighSchoolDiploma = 1,
  AssociateDegree,
  BachelorsDegree,
  MastersDegree,
  DoctoralDegree,
}

export type IAcademicDiscipline = DefaultCatalog & {
  priceMultiplier: number;
};

export type IAcademicLevel = DefaultCatalog & {
  priceMultiplier: number;
  shortestDeadline: number;
  idAcademicDegree: AcademicDegrees;
};

export enum ExtraFeatureTypes {
  PremiumWriter = 1,
  OriginalityReport,
  ExtraQualityCheck,
  PreferredWriter,
  AIReport,
  DraftFeature,
}

export type IExtraFeature = DefaultCatalog & {
  flatSurcharge: number;
  percentageSurcharge: number;
  idType: ExtraFeatureTypes;
};

export enum EPaperUnitTypes {
  Units = "units",
  Words = "words",
}

export type IPaperType = DefaultCatalog & {
  priceMultiplier: number;
  unit: IPaperTypesUnit;
};

export type ILineSpacing = DefaultCatalog & {
  wordCountMultiplier: number;
};

export type CitationStyleStatuses = "1" | "2" | "3" | "4"; // todo: Temporary

export type ICitationStyle = {
  id: number;
  dateAdded: string;
  name: string;
  description?: string;
  customerDescription?: string;
  writerDescription?: string;
  idStatus: CitationStyleStatuses;
};

export type IPaperTypesUnit = DefaultCatalog & {
  wordCount: number;
  customWordChunkSize?: number;
  minWordCount?: number;
  idName: EPaperUnitTypes;
  lineSpacings?: ILineSpacing[];
  citationStyles: ICitationStyle[];
};

export enum IWorkTypeNatures {
  Writing = 1,
  Rewriting,
  Editing,
}

export type IWorkType = DefaultCatalog & {
  priceMultiplier: number;
  idNature: IWorkTypeNatures;
};

export type ICommunicationChannel = DefaultCatalog;

export type IOrderFormConfig = {
  academicDisciplines: IAcademicDiscipline[];
  academicLevels: IAcademicLevel[];
  extraFeatures: IExtraFeature[];
  paperTypes: IPaperType[];
  workTypes: IWorkType[];
  communicationChannels: ICommunicationChannel[];

  // extra features
  premiumWriterSetting?: PreferredWriterSetting;
  extraQualityCheckSetting?: ExtraQualityCheckSetting;
  originalityReportSetting?: OriginalityReportSetting;
  preferredWriterSetting?: PreferredWriterSetting;
  AIReportSetting?: AIReportSetting;
  draftFeatureSetting?: DraftFeatureSetting;
};

type BaseExtraFeatureSetting = {
  id: number;
  customerName?: string;
  customerDescription?: string;
  flatSurcharge?: number;
  percentageSurcharge?: number;
};

export type PreferredWriterSetting = BaseExtraFeatureSetting;
export type PremiumWriterSetting = BaseExtraFeatureSetting;
export type ExtraQualityCheckSetting = BaseExtraFeatureSetting & {
  freeCheckMinPrice?: number;
};

export type AIReportSetting = BaseExtraFeatureSetting & {
  freeServiceMinPrice?: number;
};

export type DraftFeatureSetting = BaseExtraFeatureSetting & {
  freeServiceMinPrice?: number;
};

export type OriginalityReportSetting = BaseExtraFeatureSetting & {
  freeCheckMinPrice?: number;
};

export interface IPriceQuoteRequest {
  idWorkType: number;
  idAcademicLevel: number;
  idAcademicDiscipline: number;
  deadline: number;
  idPaperType: number;
  idLineSpacing: number;
  idCitationStyle?: number;
  idExtraFeatures: number[];
  discountCode: null;
  words?: number;
  units?: number;

  // extra features
  preferredWriter?: boolean;
  premiumWriter?: boolean;
  originalityReport?: boolean;
  extraQualityCheck?: boolean;
  aiReport?: boolean;
  draftFeature?: boolean;
}

export type ExtraFeatureSetting<T, ExtraFeatureTypes> = T & {
  // only internal use
  idExtraFeatureType: ExtraFeatureTypes;
};

export type ExtraFeatureAnySetting =
  | ExtraFeatureSetting<PremiumWriterSetting, ExtraFeatureTypes.PremiumWriter>
  | ExtraFeatureSetting<PreferredWriterSetting, ExtraFeatureTypes.PreferredWriter>
  | ExtraFeatureSetting<ExtraQualityCheckSetting, ExtraFeatureTypes.ExtraQualityCheck>
  | ExtraFeatureSetting<OriginalityReportSetting, ExtraFeatureTypes.OriginalityReport>
  | ExtraFeatureSetting<AIReportSetting, ExtraFeatureTypes.AIReport>
  | ExtraFeatureSetting<AIReportSetting, ExtraFeatureTypes.DraftFeature>;

export interface IPriceQuoteResponse {
  dynamicTotalPrice: number;
}

export interface ICachedPriceQuote {
  requestData: string;
  responseData: IPriceQuoteResponse;
}

export interface OrderFormState {
  config?: IOrderFormConfig | null;
  isConfigLoading: boolean;
  isPriceQuoteLoading: boolean;
  cachedPriceQuotes: ICachedPriceQuote[];
}

export type StateSchema = { orderForm: OrderFormState };

export type AppGetState = () => StateSchema;

export type LocalDispatch = ThunkDispatch<StateSchema, unknown, AnyAction>;

export const useAppSelector: TypedUseSelectorHook<StateSchema> = useSelector;
export const useAppDispatch = (): LocalDispatch => useDispatch<LocalDispatch>();

export type CalculatorFormState = Partial<IPriceQuoteRequest> & { selectedFeatures?: number[] };

export type FormPaperUnit = "units" | "words";
