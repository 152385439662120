"use client";
import s from "./PriceCalculator.module.scss";
import {
  AnimatedNumber,
  CalculatorForm,
  getExtraFeaturesFromEntity,
  renderExtraFeatures,
  useCalculatorForm,
} from "lib/priceCalculator";
import cn from "classnames";
import { baseUrl, orderFormUrl } from "@/const";
import { Button } from "../UI/Buttons/Button";
import { IconImage } from "@/components/UI/IconImage";
import IconArrowRight from "public/img/icons/svg/arrow-right.svg";
import IconPlus from "public/img/icons/svg/plus.svg";

type PriceCalculatorProps = {
  className?: string;
  withPremiumFeatures?: boolean;
};

export const PriceCalculator = ({ className, withPremiumFeatures }: PriceCalculatorProps) => {
  const { submit, price, config, ...formProps } = useCalculatorForm(String(baseUrl), String(orderFormUrl));

  const selectedFeatures = formProps.formValues.selectedFeatures || [];

  return (
    <div
      className={cn(
        s.calculator,
        {
          [s.calculatorPremium]: withPremiumFeatures,
        },
        className
      )}
      suppressHydrationWarning
    >
      <div className={s.main}>
        <div className={s.heading}>
          <IconImage size={40} icon="calculator" priority />
          <div>Price Calculator</div>
        </div>
        <CalculatorForm {...formProps} />
        <div className={s.bottom}>
          {!!Number(price) && (
            <>
              <div className={s.priceText}>Starting at</div>
              <div className={s.price}>
                <IconImage size={42} icon="dollar-sign" />
                <span className={s.priceInt}>
                  <AnimatedNumber number={Math.floor(Number(price))} />
                </span>
                <span className={s.priceDec}>
                  <AnimatedNumber number={Number(price) * 100 - Math.floor(Number(price)) * 100} showZeros />
                </span>
              </div>
            </>
          )}

          <Button
            iconPosition="suffix"
            icon={IconArrowRight}
            type="accent"
            size="fullwidth"
            onClick={() => submit()}
          >
            Continue
          </Button>
        </div>
      </div>
      {withPremiumFeatures && (
        <div className={s.featuresBlock}>
          <div className={s.featuresBlockTop}>
            <IconImage size={40} icon="star" className={s.featuresBlockImg} />
            <div className={s.featuresHeading}> Extra Features</div>
            <p className={s.featuresSubHeading}>
              Get the most out of expert help and guidance by adding premium services.
            </p>
          </div>

          <div className={s.featuresList}>
            {renderExtraFeatures({
              items: getExtraFeaturesFromEntity(config || undefined),
              updateValues: formProps.updateValues,
              selectedIds: selectedFeatures,
              render: (idExtraFeatureType, isActive, onClick, text) => (
                <Button
                  key={idExtraFeatureType}
                  type="primary"
                  outline={!selectedFeatures.includes(idExtraFeatureType)}
                  className={cn(s.featuresBtn, {
                    [s.featuresBtnActive]: selectedFeatures.includes(idExtraFeatureType),
                  })}
                  onClick={onClick}
                  icon={IconPlus}
                  iconPosition="suffix"
                >
                  {text}
                </Button>
              ),
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceCalculator;
