import cn from "classnames";
import { MouseEvent } from "react";

export type DropdownRangeToggleButtonProps = {
  onClick?: (event: MouseEvent) => void;
  error?: boolean;
  value?: number;
  items: {
    id: number;
    value: number | string;
  }[];

  setValue?: (value: number) => void;
  minDisabled?: boolean;
  plusDisabled?: boolean;
};

export const DropdownRangeToggleButton = ({
  onClick,
  error,
  items,
  value,
  setValue,
  minDisabled,
  plusDisabled,
}: DropdownRangeToggleButtonProps) => {
  const toPrevVal = () => {
    const targetIndex = items.findIndex((el) => el.id === value);
    if (targetIndex > 0) setValue?.(items[targetIndex - 1].id);
  };

  const toNextVal = () => {
    const targetIndex = items.findIndex((el) => el.id === value);
    if (targetIndex < items.length - 1) setValue?.(items[targetIndex + 1].id);
  };

  return (
    <>
      <button
        type="button"
        aria-label="minus"
        className={cn("core-dropdown__range-btn", "core-dropdown__range-btn--minus")}
        disabled={minDisabled}
        onClick={toPrevVal}
      />
      <button
        type="button"
        className={cn("core-calculator-input", "core-dropdown__toggle-btn")}
        onClick={onClick}
      >
        {items.find((item) => item.id === value)?.value}
      </button>
      <button
        type="button"
        aria-label="plus"
        className={cn("core-dropdown__range-btn", "core-dropdown__range-btn--plus")}
        disabled={plusDisabled}
        onClick={toNextVal}
      />
    </>
  );
};
