import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ICachedPriceQuote, IOrderFormConfig, OrderFormState } from "../types/orderForm";
import { StateSchema } from "../types/orderForm";

const initialState: OrderFormState = {
  config: null,
  isConfigLoading: false,
  isPriceQuoteLoading: false,
  cachedPriceQuotes: [],
};

export const OrderFormSlice = createSlice({
  name: "orderForm",
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<IOrderFormConfig>) => {
      state.config = action.payload;
    },
    setConfigLoading: (state, action: PayloadAction<boolean>) => {
      state.isConfigLoading = action.payload;
    },
    setPriceQuoteLoading: (state, action: PayloadAction<boolean>) => {
      state.isPriceQuoteLoading = action.payload;
    },
    setCachedPriceQuotes: (state, action: PayloadAction<ICachedPriceQuote>) => {
      state.cachedPriceQuotes = [...state.cachedPriceQuotes, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfig, setConfigLoading, setPriceQuoteLoading, setCachedPriceQuotes } =
  OrderFormSlice.actions;

export const orderFormConfigSelector = ({ orderForm }: StateSchema): IOrderFormConfig | undefined | null =>
  orderForm.config;
export const orderFormConfigLoadingSelector = ({ orderForm }: StateSchema): boolean =>
  orderForm.isConfigLoading;

export const OrderFormSliceReducer = OrderFormSlice.reducer;
