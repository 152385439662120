import type { IOrderFormConfig, IPriceQuoteRequest } from "../types/orderForm";
import { useCallback, useMemo } from "react";
import { omit } from "lodash";

const removeFalsyValues = (obj: Record<string, any>) => {
  let res = { ...obj };
  Object.keys(res).forEach((key) => {
    // Match falsy values & empty arrays
    if (!res[key] || (Array.isArray(res[key]) && !res[key].length)) {
      delete res[key];
    }
  });
  return res;
};

export const useOrderForm = (
  orderFormConfid: Partial<IOrderFormConfig> | null | undefined,
  values: Partial<IPriceQuoteRequest> & {
    selectedFeatures?: number[];
  },
  defaults: Partial<
    IPriceQuoteRequest & {
      selectedFeatures?: number[];
    }
  >,
  baseUrl: string
) => {
  const config = orderFormConfid || {};

  const paperType = config.paperTypes?.find(({ id }) => id === values.idPaperType);
  const paperUnit = paperType?.unit || config.paperTypes?.[0].unit;
  const lineSpacings = paperUnit?.lineSpacings;
  const lineSpacing = lineSpacings?.find(({ id }) => id === values.idLineSpacing);
  const citationStyles = paperUnit?.citationStyles;
  const minWordCount = paperUnit?.minWordCount || 100;
  const wordChunkSize = paperUnit?.customWordChunkSize || 25;
  const academicLevel =
    config.academicLevels?.find(({ id }) => id === values.idAcademicLevel) || config.academicLevels?.[0];
  const shortestDeadline = academicLevel?.shortestDeadline || 336;

  /**
   * Validate payload before request
   * @returns {boolean} flag -> isValid
   */
  const validatePayload = useCallback(
    (payload: Partial<IPriceQuoteRequest>) => {
      // Validation
      if (wordChunkSize && payload.words) {
        const div = payload.words % wordChunkSize;
        if (div !== 0) return false;
      }

      if (!payload.idAcademicLevel) return false;

      return !(payload.words && payload.units);
    },
    [wordChunkSize]
  );

  const queryString = useMemo(() => {
    const qs = new URLSearchParams();

    // Throw all params to orderform
    const params = omit(removeFalsyValues({ ...defaults, ...values }), [
      "premiumWriter",
      "extraQualityCheck",
      "originalityReport",
    ]);

    Object.entries(params).forEach(([key, value]) => {
      qs.set(key, String(value));
    });
    return `https://app.${baseUrl || location.hostname}/order?${qs}`;
  }, [baseUrl, defaults, values]);

  /**
   * On submit button clicked -> redirect to orderform app
   */
  const submit = useCallback(
    (suffix?: string) => {
      window.location.href = queryString + (suffix || "");
    },
    [queryString]
  );

  return {
    paperType,
    paperUnit,
    lineSpacings,
    lineSpacing,
    citationStyles,
    wordChunkSize,
    minWordCount,
    academicLevel,
    shortestDeadline,
    queryString,
    validatePayload,
    submit,
  };
};
