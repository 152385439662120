import { setCachedPriceQuotes, setPriceQuoteLoading } from "./orderFormSlice";
import type { AppGetState, LocalDispatch } from "../types/orderForm";
import type { IPriceQuoteRequest, IPriceQuoteResponse } from "../types/orderForm";
import { sanitizeExtraFeaturesRequest } from "./extraFeatures";

let fetchPriceQuotePromise: Promise<boolean> | null = null;

export const getPriceQuote =
  (
    data: Partial<IPriceQuoteRequest> & {
      selectedFeatures?: number[];
    },
    baseUrl: string,
    orderFormUrl: string
  ) =>
  async (dispatch: LocalDispatch, getState: AppGetState): Promise<IPriceQuoteResponse | undefined> => {
    const { orderForm } = getState();

    if (!orderForm.config) return;

    if (fetchPriceQuotePromise) await fetchPriceQuotePromise;

    const body = JSON.stringify(sanitizeExtraFeaturesRequest(data));

    const { orderForm: updatedFormValues } = getState();

    const cachedResult = updatedFormValues.cachedPriceQuotes.find(({ requestData }) => body === requestData);

    if (cachedResult) {
      return cachedResult.responseData;
    }

    dispatch(setPriceQuoteLoading(true));

    let result: IPriceQuoteResponse | undefined = undefined;

    fetchPriceQuotePromise = (async (): Promise<boolean> => {
      try {
        const rawResponse = await fetch(`${orderFormUrl}/price-quote`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-host": baseUrl || location.hostname,
          },
          body,
        });

        if (rawResponse.status > 299) {
          throw new Error();
        }

        result = (await rawResponse.json()) as IPriceQuoteResponse;
        dispatch(
          setCachedPriceQuotes({
            requestData: body,
            responseData: result,
          })
        );
        return true;
      } catch (err) {
        return false;
        // retry
      }
    })();

    const priceQuoteResult = fetchPriceQuotePromise ? await fetchPriceQuotePromise : false;

    dispatch(setPriceQuoteLoading(false));

    if (priceQuoteResult && result) return result;

    setTimeout(() => {
      dispatch(getPriceQuote(data, baseUrl, orderFormUrl));
    }, 5000);
  };
