import { useEffect, useState } from "react";

export const useInteraction = () => {
  const [isInteracted, setIsInteracted] = useState(false);

  useEffect(() => {
    const interactedCheck = () => {
      setIsInteracted(true);
      window.removeEventListener("scroll", interactedCheck);
      window.removeEventListener("mousemove", interactedCheck);
    };

    window.addEventListener("scroll", interactedCheck);
    window.addEventListener("mousemove", interactedCheck);

    return () => {
      window.removeEventListener("scroll", interactedCheck);
      window.removeEventListener("mousemove", interactedCheck);
    };
  }, []);

  return { isInteracted };
};
